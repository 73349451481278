<template>
  <b-card-code>
    <b-modal id="changePrivateMessage" title="Státusz állítása" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col md="6">
                <b-form-group
                  label="Kivel kapcsolatos?"
                  label-for="target_id"
                >
                  <b-form-select
                    id="target_id"
                    v-model="target_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employeeList"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Rövid leírás"
                  label-for="shortTitle"
                >
                  <b-form-input
                    id="shortTitle"
                    v-model="shortTitle"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Megjegyzés"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="title"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Összeg"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="price"
                    type="number"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Levonás dátuma"
                  label-for="selected_date"
                >
                  <b-form-input
                    id="selected_date"
                    v-model="selected_date"
                    type="month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('changePrivateMessage')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="updatePrivateMessage()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-6">
        <h4>Levonások</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/private_comment/archive')">Archivált levonások</button>
        <button class="btn btn-primary" @click="$router.push('/projects/create?q=Megjegyzés')">Új létrehozás</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'status'"
          class="text-center"
        >
          <feather-icon
            v-if="props.row.status == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
        </div>

        <span v-else-if="props.column.field === 'price'">
          <span class="badge badge-success text-light" v-if="props.row.price > 0">+{{ parseInt(props.row.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
          <span class="badge badge-danger text-light" v-else>{{ parseInt(props.row.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
        </span>

        <span v-else-if="props.column.field === 'selected_date'">
          <span v-if="props.row.selected_date !== null">{{ props.row.selected_date.slice(0, -3) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="acceptComment(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Státusz változtatás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editComment(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveComment(props.row.id)">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50"
                />
                <span>Archiválás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      shortTitle: null,
      columns: [
        {
          label: 'Státusz',
          field: 'status',
          filterOptions: {
            enabled: false,
            placeholder: 'Státusz',
          },
        },
        {
          label: 'Rövid megnevezés',
          field: 'shortTitle',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megnevezésben',
          },
        },
        {
          label: 'Megjegyzés',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megjegyzésben',
          },
        },
        {
          label: 'Összeg',
          field: 'price',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Levonás dátuma',
          field: 'selected_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés dátumra',
          },
        },
        {
          label: 'Kire vonatkozik?',
          field: 'target_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Ki hozta létre?',
          field: 'creator_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      title: '',
      target_name: '',
      selected_id: '',
      employeeList: [],
      price: null,
      target_id: null,
      selected_date: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getPrivateCommentList()

    axios({
      method: 'get',
      url: 'employee/list/only_name_and_id',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      res.data.forEach(project => {
        this.employeeList.push({
          value: project.id,
          text: project.name,
        })
      })
    })
  },
  methods: {
    acceptComment(id) {
      axios({
        method: 'put',
        url: `private_comment/status_change/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen változott a státusz',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getPrivateCommentList()
      }).then(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    archiveComment(id) {
      axios({
        method: 'put',
        url: `private_comment/deleted_change/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getPrivateCommentList()
      }).then(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    updatePrivateMessage() {
      axios({
        method: 'put',
        url: 'private_comment/update',
        data: {
          id: this.selected_id,
          title: this.title,
          price: this.price,
          selected_date: this.selected_date,
          target_id: this.target_id,
          target_name: this.target_name,
          shortTitle: this.shortTitle,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getPrivateCommentList()
        this.$bvModal.hide('changePrivateMessage')
      }).then(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    editComment(id) {
      this.$bvModal.show('changePrivateMessage')
      this.selected_id = id
      this.title = this.rows.find(row => row.id === id).title
      this.target_id = this.rows.find(row => row.id === id).target_id
      this.selected_date = this.rows.find(row => row.id === id).selected_date.slice(0, -3)
      this.price = this.rows.find(row => row.id === id).price
      this.shortTitle = this.rows.find(row => row.id === id).shortTitle
    },
    deleteComment(id) {
      axios({
        method: 'delete',
        url: `private_comment/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getPrivateCommentList()
      })
    },
    getPrivateCommentList() {
      axios({
        method: 'get',
        url: 'private_comment/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
